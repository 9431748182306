import BaseModal from "plugins/modal/modal.js";
import { html } from "lit";
import { _converse, api, converse } from '@converse/headless';
import dayjs from 'dayjs';
import { __ } from 'i18n';

export default class SearchDialog extends BaseModal{
    initialize() {
        super.initialize();
        this.events={'click .btn-pdf': 'doPDF','keyup #pade-search-keywords': 'keyUp'}
        this.listenTo(this.model, 'change', this.render);
    }
    toHTML() {
      return html`<div class="modal-dialog modal-xl" role="document" id="plugin-search-modal"> <div class="modal-content">
             <div class="modal-header"><h1 class="modal-title" id="converse-plugin-search-label">Search</h1><button type="button" class="close" data-dismiss="modal">&times;</button></div>
             <div class="modal-body">
             <input value="${this.model.get('keyword') || ''}" id="pade-search-keywords" class="form-control" type="text" placeholder="${__('Type a query and press [Enter] to search')}" ><p/><div id="pade-search-results"></div>
             </div>
             <div class="modal-footer">
             <button type="button" class="btn btn-success btn-pdf">PDF</button>
             <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> </div>
             </div>
             </div> </div>`;
    }
    afterRender() {
      var that = this;
      this.el = document.querySelector("#converse-modals");
      this.el.addEventListener('shown.bs.modal', function()
      {
          if (that.model.get("keyword"))
          {
              that.el.querySelector('#pade-search-keywords').value = that.model.get("keyword");
              that.doSearch();
          }
          else {
            that.el.querySelector('#pade-search-keywords').focus();
          }

      }, false);
    }

    keyUp(ev) {
        if (ev.key === "Enter")
        {
            var keyword = this.el.querySelector("#pade-search-keywords").value.trim();
            this.model.set("keyword", keyword)
            this.doSearch();
        }
    }
    doPDF() {
        const margins = {
          top: 70,
          bottom: 40,
          left: 30,
          width: 550
        };
        const pdf = new jspdf.jsPDF('p','pt','a4');

        pdf.autoTable({
            head: [[__('Date'), __('Person'), __('Message')]],
            body: this.model.get("pdf_body"),
            columnStyles: {
                0: {cellWidth: 100},
                1: {cellWidth: 100},
                2: {cellWidth: 300}
            }
        })

        const view = this.model.get("view");
        const roomLabel = view.model.getDisplayName() || view.model.get("jid");
        pdf.save(roomLabel + '.pdf')
    }
    doSearch() {
        var view = this.model.get("view");
        var jid = view.model.get("jid");
        var type = view.model.get("type");
        var groupchat = view.model.get("type") == "chatroom";
        var method = _converse.api.settings.get("search_method");

        var that = this;
        var keyword = that.model.get("keyword");
        var searchRegExp = undefined;
        var tagRegExp = undefined;
        var pdf_body = [];

        console.debug("doSearch", keyword, jid);

        if (keyword != "")
        {
            searchRegExp = new RegExp('^(.*)(\s?' + keyword + ')', 'i');
            tagRegExp = new RegExp("(\\b" + keyword + "\\b)", "im");
        }
        var searchResults = that.el.querySelector("#pade-search-results");
        searchResults.innerHTML = "No Match";
        var html = "<table style='margin-left: 15px'><tr><th>"+__('Date')+"</th><th>"+__('Person')+"</th><th>"+__('Message')+"</th></tr>";

        if (method == "mam")
        {
            _converse.api.archive.query({before: '', max: 999, 'groupchat': groupchat, 'with': jid}).then(function(result)
            {
                const messages = result.messages;

                for (var i=0; i<messages.length; i++)
                {
                    if (messages[i].querySelector('body'))
                    {
                        var body = messages[i].querySelector('body').innerHTML;
                        var delay = messages[i].querySelector('forwarded').querySelector('delay');
                        var from = messages[i].querySelector('forwarded').querySelector('message').getAttribute('from');
                        var time = delay ? delay.getAttribute('stamp') : dayjs().format();
                        var pretty_time = dayjs(time).format('MMM DD HH:mm:ss');
                        var pretty_from = type === "chatroom" ? from.split("/")[1] : from.split("@")[0];
                        if (!searchRegExp || searchRegExp.test(body)) pdf_body.push([pretty_time, pretty_from, body]);

                        html =  html + that.makeHtml(searchRegExp, tagRegExp, body, pretty_time, pretty_from);
                    }
                }

                html =  html + "</table>";
                searchResults.innerHTML = html;
                that.model.set("pdf_body", pdf_body);
            });
        }
        else {
            var messages = view.model.messages.models;

            for (var i=0; i<messages.length; i++)
            {
                var body = messages[i].get('message');
                var from = messages[i].get('from');
                var pretty_time = dayjs(messages[i].get('time')).format('MMM DD HH:mm:ss');
                var pretty_from = from;
                if (from) pretty_from =  messages[i].get('type') === "groupchat" ? from.split("/")[1] : from.split("@")[0];
                if (!searchRegExp || searchRegExp.test(body)) pdf_body.push([pretty_time, pretty_from, body]);

                html =  html + this.makeHtml(searchRegExp, tagRegExp, body, pretty_time, pretty_from);
            }

            html =  html + "</table>";
            searchResults.innerHTML = html;
            that.model.set("pdf_body", pdf_body);
        }
    }
    makeHtml(searchRegExp, tagRegExp, body, pretty_time, pretty_from)
    {
        let html = "";

        if (!searchRegExp || searchRegExp.test(body))
        {
            var tagged = tagRegExp ? body.replace(tagRegExp, "<span style=background-color:#FF9;color:#555;>$1</span>") : body;
            html = html + "<tr><td>" + pretty_time + "</td><td>" + pretty_from + "</td><td>" + tagged + "</td></tr>";
        }
        return html;
    }
}

api.elements.define('search-dialog', SearchDialog);